<template>
  <div class="dashboard">
    <div class="title-section">
      <h1 class="title">
        By Apps
      </h1>
      <div class="export-filters">
        <export :grid-api="api || undefined" />
      </div>
    </div>
    <section class="content">
      <filters :variant-options="variantOptions" />

      <section>
        <grid
          :columns="columns"
          :default-hidden-columns="defaultHiddenColumns"
          :data="kpis"
          :loading="$apollo.loading"
          :auto-group-column="autoGroupColumnDef"
          :show-footer="false"
          :side-bar="sidebar"
          :group-default-expanded="1"
          :default-export-params="defaultExportParams"
          @ready="api = $event"
        />
      </section>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import * as moment from 'moment';
import { GET_APPS, GET_APPS_DASHBOARD_DATA } from '../gql-requests';
import GridMixin from '../mixins/GridMixin';
import Export from '../components/byApps/Export';
import { KPIs } from '../data/kpis';

export default {
  components: { Export },
  mixins: [GridMixin],
  data() {
    const today = new Date();
    return {
      api: null,
      apps: [],
      variantOptions: {},
      selectedYear: today.getFullYear(),
      selectedWeek: moment().isoWeek() - 1,
      minDate: new Date(2017, 0, 1),
      maxDate: new Date(today.getFullYear(), today.getMonth(), today.getDate()),
      dateRange: [new Date(today.getFullYear(), today.getMonth() - 2, today.getDate()), new Date(today.getFullYear(), today.getMonth() - 1, today.getDate())],
      appsDashboardData: [],
      rawKpis: {},
      sidebar: {
        toolPanels: [
          {
            id: 'filters',
            labelDefault: 'Filters',
            labelKey: 'filters',
            iconKey: 'filter',
            toolPanel: 'agFiltersToolPanel',
            toolPanelParams: {
              suppressExpandAll: true,
              suppressFilterSearch: true,
            },
            minWidth: 180,
            maxWidth: 400,
            width: 250,
          },
          {
            id: 'columns',
            labelDefault: 'Columns',
            labelKey: 'columns',
            iconKey: 'columns',
            toolPanel: 'agColumnsToolPanel',
            toolPanelParams: {
              suppressRowGroups: true,
              suppressValues: true,
              suppressPivots: true,
              suppressPivotMode: true,
              suppressColumnFilter: true,
              suppressColumnSelectAll: true,
            },
            minWidth: 225,
            width: 225,
            maxWidth: 225,
          },
        ],
      },
      defaultExportParams: {
        skipRowGroups: false,
        fileName: 'by-apps',
        shouldRowBeSkipped(params) {
          const { node } = params;
          const hasDefinedSubKey = (obj) => {
            if (typeof obj !== 'object' || obj === null) {
              return false;
            }

            return Object
              .keys(obj)
              .some((key) => obj[key] !== undefined);
          };

          const isEmptyRow = node.key
            && !hasDefinedSubKey(node.aggData);

          return isEmptyRow;
        },
        processCellCallback(params) {
          const { value, node, column } = params;
          if (!value && node.key) {
            if (column.colId === 'application') {
              return node.parent.key;
            }
            if (column.colId === 'country') {
              return 'Global';
            }
            return node.key;
          }
          return value ?? '';
        },
      },
    };
  },
  computed: {
    ...mapGetters('filters', ['getDateRange', 'getGranularity', 'getVariant']),
    autoGroupColumnDef() {
      return {
        headerName: 'KPI',
        minWidth: 300,
        pinned: 'left',
        resizable: true,
        suppressMovable: true,
        cellRendererParams: {
          suppressCount: true,
          innerRenderer: (params) => {
            if (params.value === KPIs.CHURN) {
              return this.aggregationKpis?.[params.node.parent.key]?.[params.value].externalUrl ?? params.value;
            }
            return params.value;
          },
        },
        valueGetter: (params) => {
          if (params.data?.appName === 'Total') {
            return params.data?.kpi;
          }
          const field = 'country';
          return params.data?.[field];
        },
      };
    },
    timePeriods() {
      const timePeriods = [];
      const timePeriod = moment(this.getDateRange[0]);

      if (this.getGranularity === 'weekly') {
        timePeriod.date(1).day(8);
        if (timePeriod.date() > 7) {
          timePeriod.day(-6);
        }
      }

      const end = moment(this.getDateRange[1]).endOf('month');
      const fieldFormat = this.getGranularity === 'weekly' ? 'YYYYMMDD' : 'YYYYMM';
      const labelFormat = this.getGranularity === 'weekly' ? 'YY/MM/DD' : 'MMMM YYYY';
      while (timePeriod.isSameOrBefore(end)) {
        const field = timePeriod.format(fieldFormat);

        let label;
        if (this.getGranularity === 'weekly') {
          const startOfWeek = timePeriod.format(labelFormat);
          const endOfWeek = timePeriod.clone().add(6, 'days').format(labelFormat);
          label = `${startOfWeek} - ${endOfWeek}`;
        } else {
          label = timePeriod.format(labelFormat);
        }

        timePeriods.push({
          field,
          label,
        });
        const period = this.getGranularity === 'weekly' ? 'week' : 'month';
        timePeriod.add(1, period);
      }
      return timePeriods;
    },
    defaultHiddenColumns() {
      const columns = [];
      this.timePeriods.forEach((period) => {
        columns.push(`${period.field}.diffPerc`, `${period.field}.diff`);
      });

      return columns;
    },
    columns() {
      const columns = [];

      columns.push({
        field: 'appName',
        colId: 'application',
        headerName: 'App',
        filter: true,
        pinned: 'left',
        filterParams: {
          suppressSorting: true,
          debounceMs: 200,
        },
        rowGroup: true,
        hide: true,
      });

      columns.push({
        colId: 'kpi',
        headerName: 'KPI',
        rowGroup: true,
        hide: true,
        valueGetter: (params) => {
          if (params.data?.appName === 'Total') {
            return undefined;
          }
          const field = 'kpi';
          return params.data?.[field];
        },
      });

      columns.push({
        colId: 'country',
        headerName: 'Country',
        hide: true,
        valueGetter: (params) => {
          if (params.data?.appName === 'Total') {
            return undefined;
          }
          const field = 'country';
          return params.data?.[field];
        },
      });

      this.timePeriods.forEach((period) => {
        columns.push({
          headerName: period.label,
          colId: period.field,
          children: [
            {
              headerName: 'Actual',
              field: `${period.field}.value`,
              type: 'numericColumn',
              aggFunc: this.customAggregation,
              resizable: true,
              width: 110,
              valueFormatter: (val) => {
                const kpi = val.data?.kpi ?? val.node?.key;
                return this.formatKpi(val.value, kpi);
              },
            },
            {
              headerName: this.getGranularity === 'weekly' ? 'WoW %' : 'MoM %',
              field: `${period.field}.diffPerc`,
              type: 'numericColumn',
              aggFunc: this.customAggregation,
              resizable: true,
              width: 110,
              cellStyle: (params) => this.cellStyle(params),
            },
            {
              headerName: this.getGranularity === 'weekly' ? 'WoW' : 'MoM',
              field: `${period.field}.diff`,
              type: 'numericColumn',
              aggFunc: this.customAggregation,
              resizable: true,
              width: 110,
              hide: true,
              valueFormatter: (val) => {
                const kpi = val.data?.kpi ?? val.node?.key;
                return this.formatKpi(val.value, kpi);
              },
              cellStyle: (params) => this.cellStyle(params),
            },
          ],
        });
      });

      return columns;
    },
    kpis() {
      return Object.values(this.rawKpis).map((item) => ({ ...item }));
    },
    dataChange() {
      const { appsDashboardData } = this;
      if (appsDashboardData) {
        return {
          appsDashboardData,
        };
      }
      return null;
    },
  },
  watch: {
    appsDashboardData() {
      this.formatKpiAgGrid();
    },
    dataChange: {
      handler(val) {
        if (val) {
          this.formatKpiAgGrid();
        }
      },
      deep: true,
    },
  },
  methods: {
    customAggregation(params) {
      const { values, rowNode, column } = params;
      const kpi = rowNode?.key;
      const app = rowNode?.parent?.key;
      const field = column?.colDef?.field;

      if (rowNode.level > 0 && values.filter(Boolean).length > 0) {
        const [month, property] = field.split('.');
        return this.aggregationKpis?.[app]?.[kpi]?.[month]?.[property];
      }

      return undefined;
    },
    formatDate(year, month, subMonths) {
      const date = new Date(year, month - subMonths - 1);

      return `${date.getFullYear()}${this.withLeadingZero(date.getMonth() + 1)}`;
    },
    formatWeekDate(currentWeek, subWeeks) {
      const date = new Date(currentWeek);
      date.setDate(date.getDate() - (subWeeks * 7));

      return `${date.getFullYear()}${this.withLeadingZero(date.getMonth() + 1)}${this.withLeadingZero(date.getDate())}`;
    },
    formatKpiAgGrid() {
      const rowData = {};
      const aggregationData = {};

      this.appsDashboardData
        .forEach((entry) => {
          const {
            appName, kpi, country,
            currentYear, currentMonth, currentWeek,
            monthlyByApps, weeklyByApps,
          } = entry;

          const key = `${appName}-${kpi}-${country}`;

          if (country !== null) {
            if (!rowData[key]) {
              rowData[key] = {
                appName,
                kpi,
                country: country !== 'All' ? country : undefined,
              };
            }

            const date = monthlyByApps
              ? this.formatDate(currentYear, currentMonth, 0)
              : this.formatWeekDate(currentWeek, 0);

            if (!rowData[key][date]) {
              rowData[key][date] = {};

              if (monthlyByApps) {
                monthlyByApps.forEach((monthly) => {
                  const monthDate = this.formatDate(currentYear, currentMonth, monthly.month);
                  rowData[key][monthDate] = {
                    value: monthly.value,
                    diff: monthly.monthOverMonth,
                    diffPerc: this.formatCompletion(monthly.monthOverMonthPercent),
                  };
                });
              } else {
                weeklyByApps.forEach((weekly) => {
                  const weekDate = this.formatWeekDate(currentWeek, weekly.week);
                  rowData[key][weekDate] = {
                    value: weekly.value,
                    diff: weekly.weekOverWeek,
                    diffPerc: this.formatCompletion(weekly.weekOverWeekPercent),
                  };
                });
              }
            }
          } else {
            if (!aggregationData[appName]) {
              aggregationData[appName] = {};
            }

            const revenueCatId = this.apps.find((app) => app.name === appName)?.revenuecat_id;
            const externalUrl = revenueCatId && kpi === KPIs.CHURN
              ? `<a target="_blank" href="https://app.revenuecat.com/charts/actives_movement?chart_type=Line&filter=app_config_id%3A%3D%3A${revenueCatId}&resolution=1">${kpi}</a>`
              : undefined;

            if (!aggregationData[appName][kpi]) {
              aggregationData[appName][kpi] = {
                externalUrl,
              };
            }

            if (monthlyByApps) {
              monthlyByApps.forEach((monthly) => {
                const monthDate = this.formatDate(currentYear, currentMonth, monthly.month);
                aggregationData[appName][kpi][monthDate] = {
                  value: monthly.value,
                  diff: monthly.monthOverMonth,
                  diffPerc: this.formatCompletion(monthly.monthOverMonthPercent),
                };
              });
            } else {
              weeklyByApps.forEach((weekly) => {
                const weekDate = this.formatWeekDate(currentWeek, weekly.week);
                aggregationData[appName][kpi][weekDate] = {
                  value: weekly.value,
                  diff: weekly.weekOverWeek,
                  diffPerc: this.formatCompletion(weekly.weekOverWeekPercent),
                };
              });
            }
          }
        });

      this.rawKpis = rowData;
      this.aggregationKpis = aggregationData;
    },
  },
  apollo: {
    appsDashboardData: {
      query: GET_APPS_DASHBOARD_DATA,
      skip() {
        return !this.getDateRange.length;
      },
      variables() {
        const variables = {
          weekly: this.getGranularity === 'weekly',
        };

        if (this.getDateRange.length) {
          const from = new Date(this.getDateRange[0]);
          const to = new Date(this.getDateRange[1]);
          variables.from = `${from.getFullYear()}-${this.withLeadingZero(from.getMonth() + 1)}`;
          variables.to = `${to.getFullYear()}-${this.withLeadingZero(to.getMonth() + 1)}`;
        }

        return variables;
      },
    },
    apps: {
      query: GET_APPS,
    },
  },
};
</script>

<style lang="scss" scoped>
.dashboard {
  display: flex;
  flex-direction: column;
  height: 100%;

  .filters {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding-bottom: 10px;
  }

  .content {
    flex: 1;
    min-height: 1px;
    display: flex;
    flex-direction: column;
    max-height: 100%;

    .content-wrapper {
      flex: 1;
      display: flex;
      max-height: 100%;
    }
  }

  .week-picker ::v-deep .dropdown .datepicker-content .datepicker-body .datepicker-row:hover {
    background: #f2f2f2;

    & .datepicker-cell {
      border-radius: 0;

      &:first-child {
        border-top-left-radius: 4px;
        border-bottom-left-radius: 4px;
      }
      &:last-child {
        border-top-right-radius: 4px;
        border-bottom-right-radius: 4px;
      }
    }
  }

  .week-picker .datepicker-content .datepicker-body.datepicker-row {
    background: green;
  }

  .weekly-date {
    display: flex;
    align-items: center;

    & > :first-child {
      margin-right: 10px;
    }
  }
}

.export-filters {
  display: flex;
  align-items: center;

  & > * {
    margin-left: 10px;
  }
}
</style>
